<template>
  <div>
    <div class="d-flex align-center">
      <span class="text-h4 mr-4">Customers</span>
      <v-btn fab color="primary" small outlined :to="{ name: 'NewCustomer' }">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-sheet rounded outlined class="mt-4 pa-6" width="100%">
      <CustomersTable />
    </v-sheet>
  </div>
</template>

<script>
import CustomersTable from '@/components/CustomersTable';
export default {
  components: {
    CustomersTable,
  },
};
</script>
