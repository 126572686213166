<template>
  <v-data-table :headers="headers" :items="customers" :loading="loading">
    <template v-slot:item.actions="{ item }">
      <v-icon small color="error" @click="deleteCustomer(item)">mdi-delete</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      headers: [
        { text: 'FIRST NAME', value: 'firstName' },
        { text: 'LAST NAME', value: 'lastName' },
        { text: 'STREET', value: 'address.street' },
        { text: 'POSTCAL CODE', value: 'address.postalCode' },
        { text: 'CITY', value: 'address.city' },
        { text: 'PHONE', value: 'phoneNumber' },
        { value: 'actions' },
      ],
    };
  },
  created() {
    this.$store.dispatch('customers/getAllCustomers');
  },
  computed: {
    ...mapState('customers', ['customers', 'loading']),
  },
  methods: {
    deleteCustomer(customer) {
      if (confirm('Zeker weten?')) this.$store.dispatch('customers/deleteCustomer', customer);
    },
  },
};
</script>
